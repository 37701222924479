import AddProveedor from '../Containers/Proveedores/AddProveedores';

const routesList = [
  {
    auth: false,
    component: AddProveedor,
    exact: true,
    name: 'proveedorForm',
    path: '/',
  },
];

export default routesList;
