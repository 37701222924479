import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: 'json',
});

const validateCedPadron = _cedula => {
  return axiosInstance.get(`
    /padron/validateCedula/${_cedula}`);
};

const validateAdhesionByCed = _cedula => {
  return axiosInstance.get(
    `/proveedores/validateAdhesion/${_cedula}`,
  );
};

const saveAdhesion = _info => {
  return axiosInstance.post(
    '/proveedores/saveAdhesion',
    _info,
  );
};

const saveAttachedFiles = filesInfo => {
  const data = {
    filesInfo,
  };

  return axiosInstance.post(
    '/proveedores/saveFiles',
    filesInfo,
  );
};

const deleteProveedor = codProveedor => {
  const data = {
    codProveedor,
  };

  return axiosInstance.post(
    '/proveedores/deleteProveedor',
    data,
  );
};

export default {
  validateOnPadron: validateCedPadron,
  saveNewAdhesion: saveAdhesion,
  saveAttachedFiles,
  validateAdhesion: validateAdhesionByCed,
  deleteProveedor,
};
