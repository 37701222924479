import React, { useState } from 'react';
import * as Yup from 'yup';
import Lodash from 'lodash';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Button, Divider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Alert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import { Formik, Field } from 'formik';
import { PlnInput, PlnDropdown } from '../../Components/Input';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';
import {
  useProvincias,
  getCantonsByProvince,
  getDistritoBy,
} from '../../Core/Api/locationApi';
import adhesionesApi from '../../Core/Api/adhesionesApi';
import REGEX from '../../Core/Utils/RegExpUtils';
import useStyles from './styles';
import REGEX_UTILS from '../../Core/Utils/RegExpUtils';

let codProveedorFromAPI = 0;
const filesFailed = [];
const IBANLength = 22;

const AddProveedor = () => {
  const classes = useStyles();
  const validationSchema = Yup.object({});
  const masks = {
    fisico: '999999999',
    juridico: '9999999999'
  };

  const { data } = useProvincias();

  const [sProvincia, setSProvincia] = useState(9);
  const [sCanton, setSCanton] = useState(999);
  const [sDistrito, setSDistrito] = useState(999);

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );
  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN -', value: 999 },
  ]);
  const [distritos, setDistritos] = useState([
    { itemLabel: '- SELECCIONE EL DISTRITO -', value: 999 },
  ]);

  const [personeriaFile, setPersoneria] = useState();
  const [repLegalFile, setReprLegal] = useState();
  const [ibanFile, setIban] = useState();
  const [ibanFileJuri, setIbanJuri] = useState();
  const [cedulaFile, setCedulaFile] = useState();
  // const [codProveedorFromAPI, setCodProveedor] = useState();
  const [isFilesFailed, setIsFilesFailed] = useState(false);
  const [isProviderFailed, setIsProviderFailed] = useState(false);

  const defaultData = {
    id: '',
    nombre: '',
    legalId: '',
    legalNombre: '',
    legalTelefono: '',
    legalEmail: '',
    provincia: 9,
    canton: 999,
    distrito: 999,
    direccion: '',
    telefono: '',
    email: '',
    web: '',
    contactId: '',
    contactNombre: '',
    contactTelefono: '',
    contactEmail: '',
    IBAN: '',
    services: '',
    filePersoneria: '',
    fileCedRepresentanteLegal: '',
    fileIBAN: '',
    fileIBANJur: '',
    fileCedula: '',
  };

  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      cedula: '',
      id: '',
      nombre: '',
      legalId: '',
      legalNombre: '',
      legalTelefono: '',
      legalEmail: '',
      provincia: 9,
      canton: 999,
      distrito: 999,
      direccion: '',
      telefono: '',
      email: '',
      web: '',
      contactId: '',
      contactNombre: '',
      contactTelefono: '',
      contactEmail: '',
      IBAN: '',
      services: '',
      filePersoneria: '',
      fileCedRepresentanteLegal: '',
      fileIBAN: '',
      fileIBANJur: '',
      fileCedula: ''
    })   
  );
  const [searchBtnEnabled, setBtnSearch] = useState(false);
  const [searchBtnLabel, setBtnLabel] = useState('Verificar');
  
  const [showForm, setShowForm] = useState(false);
  // const [showJuridForm, setShowJuridForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpenDialog] = React.useState(false);

  const [mask, setMask] = useState(masks.fisico);
  const [tipoProveedor, setTipoProveedor] = useState('F');
  const [validProveedorMsj, setValidProveedorMsj] = useState('');
  const [isProveedorValid, setIsProveedorValid] = useState(true);

  const [modalBodyMsj, setModalBodyMsj] = useState('Información del proveedor ingresada correctamente.');

  const setProvincia = p => {
    /*initialValues.provincia = p;
    initialValues.canton = sCanton.value;
    initialValues.distrito = sDistrito.value;*/

    if (p !== 9) {      
      getCantonsByProvince(p).then(result => {
        setSDistrito(999);
        setSCanton(999);

        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN -',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );

          setCantones(clist);
        }
      });     
    } else {
      setCantones([
        {
          itemLabel: '- SELECCIONE EL CANTÓN -',
          value: 999,
        },
      ]);
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO -',
          value: 999,
        },
      ]);      
    }
  };

  const setCanton = c => {
    /*initialValues.provincia = sProvincia.value;
    initialValues.canton = c;*/

    if (c !== 999) {
      getDistritoBy(sProvincia.value, c).then(result => {
        setSDistrito(999);
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);
          const dlist = distritosList.map(
            ({ cod_dist_ad, des_nombre_ad }) => {
              return {
                itemLabel: des_nombre_ad,
                value: cod_dist_ad,
              };
            },
          );
          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO -',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });
    } else {
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);      
    }
  };

  const setDistrito = d => {
    /*initialValues.provincia = sProvincia.value;
    initialValues.canton = sCanton.value;
    initialValues.distrito = d;*/
    
    if (d !== 999) {
      setSDistrito(
        Lodash.find(distritos, {
          value: d,
        }),
      );
    } else {
      setSDistrito({ itemLabel: '', value: 0 });
    }
  };

  /**
 * Form validations
 * @param {*} values
 */
  const formikValidate = values => {
    const errors = {};
    const {
      cedula,
      id,
      nombre,
      telefono,
      email,
      contactId,
      IBAN,
      legalId,
      fileCedRepresentanteLegal,
      fileCedula,
      filePersoneria,
      fileIBAN,
      fileIBANJur,
    } = values;

    if (!cedula) {
      errors.cedula = 'Ingrese un número válido.';
      setValidProveedorMsj(errors.cedula);
      setShowForm(false);
    } else {
      setValidProveedorMsj('');
      setIsProveedorValid(true);
    }
  
    if (!id) {
      errors.id = 'Requerido.';
    }else if(!REGEX.digits.test(id)) {
      errors.id = 'Digite una cédula válida.';
    }

    if (!nombre) {
      errors.nombre = 'Nombre es requerido.';
    }

    if (!telefono) {
      errors.telefono = 'Teléfono requerido.';
    } else if(!REGEX.phone.test(telefono)) {
      errors.telefono = 'Formato incorrecto.';
    }

    if (!email) {
      errors.email = 'Email requerido.';
    } else if(!REGEX.email.test(email)) {
      errors.email = 'Formato de email incorrecto.';
    }

    if (contactId && !REGEX.digits.test(contactId)) {
      errors.contactId = 'Solo números.';
    }
    
    if(!IBAN || !REGEX.iban.test(IBAN) || IBAN.length > IBANLength) {
      errors.IBAN = 'Formato de cuenta IBAN inválido, debe constar de 22 dígitos.';
    }

    if (tipoProveedor !== "J" && !fileCedula) {
      errors.fileCedula = 'Documento de cédula requerido.';
    }

    if (tipoProveedor !== "J" && !fileIBAN) {
      errors.fileIBAN = 'Documento de cuenta IBAN requerido.';
    }

    // error en form juridico
    if (tipoProveedor !== "F" && !filePersoneria) {
      errors.filePersoneria = 'Personería Jurídica requerida.';
    }

    // error en form juridico
    if (tipoProveedor !== "F" && (legalId && !REGEX.digits.test(legalId)) ) {
      errors.legalId = 'Solo números.';
    }

    // error en form juridico
    if (tipoProveedor !== "F" && !fileCedRepresentanteLegal) {
      errors.fileCedRepresentanteLegal = 'Cédula de representante legal requerida.';
    }

    // error en form juridico
    if (tipoProveedor !== "F" && !fileIBANJur) {
      errors.fileIBANJur = 'Documento de cuenta IBAN requerido.';
    }

    return errors;
  };

  /**
   * Validate provider by ID
   * @param {*} _form 
   */
  const getDataByCed = async _form => {
    const { cedula } = _form.values;

    if (cedula) {
      setBtnSearch(true);
      setBtnLabel('Verificando...');

      const validateAdhesion = await adhesionesApi.validateAdhesion(
        cedula,
      );

      if (validateAdhesion.status === 200) {
        if (validateAdhesion.data.response === 0) {
          setIsProveedorValid(true);
          setValidProveedorMsj('');

          _form.setFieldValue('id', cedula);
          setShowForm(true);
        } else {
          setIsProveedorValid(false);
          setValidProveedorMsj('Este proveedor ya se encuentra registrado, por favor contactar a los teléfonos 25495504/25495519 o al correo: avalverde@plndigital.com');
        }
      } else {
        setIsProveedorValid(false);
        setValidProveedorMsj('No se pudo realizar la consulta. Intente de nuevo');
      }

      setBtnSearch(false);
      setBtnLabel('Verificar');      
    } else {
      setBtnSearch(false);
      setBtnLabel('Verificar');
      setIsProveedorValid(false);
      setValidProveedorMsj('Ingrese un número válido.');    
    }
  };

  /**
   * Update form when the provider changes
   */
  const handleProveedorChange = (event, formik) => {
    formik.resetForm();
    setTipoProveedor(event.target.value);
    setMask(event.target.value === 'F' ? masks.fisico : masks.juridico);

    setShowForm(false);
  };

  /**
   * Files attached API
   * 
   * @param {FormData} _formFiles 
   */
  const saveAttachedFilesHandler = values => {
    setIsSaving(true);
    const formFiles = new FormData();

    // Los archivos no han fallado al cargar
    if (!isFilesFailed) {
      if (tipoProveedor !== "F") {
        formFiles.append('fileCedRepresentanteLegal', values.fileCedRepresentanteLegal);
        formFiles.append('filePersoneria', values.filePersoneria);
        formFiles.append('fileIBANJur', values.fileIBANJur);
      } else {
        formFiles.append('fileCedula', values.fileCedula);
        formFiles.append('fileIBAN', values.fileIBAN);
      }
    } else {
      // AQUI TENEMOS QUE HACER EL LOOP DE LOS ARCHIVOS QUE NO SE ENVIARON
      
      for(let j=0; j < filesFailed.length; j++) {
        // console.log(filesFailed);
        formFiles.append(`${filesFailed[j]}`, values[filesFailed[j]]);
      }
    }

    formFiles.append('codProveedor', codProveedorFromAPI);

    adhesionesApi.saveAttachedFiles(formFiles).then(response => {
      if (response.status === 200) {
        if (response.data.response === 1) {
          setOpenDialog(true);
          let listFiles = '',
            message = '';
          const filesStatus = response.data.fileStatus;

          // console.log(filesStatus['error'].length);

          if (filesStatus['error'].length > 0) {
            listFiles = filesStatus['error'].map(e => e.fileName).join(", ");
            filesStatus['error'].map(e => filesFailed.push(e.formKey) );
            message = `Proveedor ingresado. Pero fallaron los siguientes archivos al enviarse: ${listFiles}. Proceda a reenviarlos!`;
          } else {
            listFiles = filesStatus['uploaded'].map(e => e.fileName).join(", ");
            message = `Proveedor ingresado. Junto con los siguientes archivos: ${listFiles}. Gracias!`;
          }

          setModalBodyMsj(message);
          setIsFilesFailed(filesStatus['error'].length > 0);
          setIsSaving(filesStatus['error'].length === 0);

        } else {
          setIsSaving(true);
          console.warn(
            'No se pudo ingresar los archivos. Intente de nuevo.',
          );          
        }
      } else {
        console.warn(
          'No se pudo completar la operacion, vuelva a intentar.',
        );        
      }
    }).catch(error => {
      adhesionesApi.deleteProveedor(codProveedorFromAPI).then(response => {
        setIsProviderFailed(true); // api error
        console.log(response);
      });
    });
  };

  const saveAdhesion = values => {
    setIsSaving(true);

    const adhesion = {
      ind_persona: (tipoProveedor === "F") ? 2 : 1,
      num_cedula: values.id,
      des_proveedor: values.nombre,
      des_contacto: values.legalNombre,
      num_telefono: values.telefono,
      des_email: values.email,
      des_cuenta: values.IBAN,
      num_contacto: values.legalId,
      des_servcios: values.services,
      num_telefono2: values.legalTelefono,
      des_email2: values.legalEmail,
      ind_estado: 2,
      des_direccion: values.direccion,
      cod_prov: (values.provincia !== 9) ? values.provincia : null,
      cod_cant: (values.canton !== 999 ) ? values.canton : null,
      cod_dist_adm: (values.distrito !== 999) ? values.distrito : null,     
      num_contacto2: values.contactId,
      des_contacto2: values.contactNombre,
      num_telefono3: values.contactTelefono,
      des_email3: values.contactEmail,
      des_web: values.web
    };

    const dataSet = {
      adhesion,
    };

    try {
      adhesionesApi
        .saveNewAdhesion(dataSet)
        .then(response => {
          setIsSaving(false);

          if (response.status === 200) {
            const apiResult = response.data;
            if (apiResult.response === 1) {
              if (apiResult.providerId) {
                codProveedorFromAPI = apiResult.providerId;
                setIsProviderFailed(false); // api error
                saveAttachedFilesHandler(values);
              }
            } else {
              setIsSaving(true);
              console.warn(
                'No se pudo ingresar el nuevo registro. Intente de nuevo.',
              );
            }
          } else {
            console.warn(
              'No se pudo completar la operacion, vuelva a intentar.',
            );
          }
        }).catch(error => {
          setIsProviderFailed(true); // api error
        });
    } catch (error) {
      setIsSaving(true);
      console.warn(error);
    }
  };

  /**
   * Modal notificacion de exito al guardar
   */
  const closeDialogHandler = () => {
    if (!isFilesFailed) {
      setTimeout(() => {
        window.location.href = '/';
      }, 1500);
    }

    setOpenDialog(false);
  };

  /**
   * Modal notificacion de error 500
   */
  const closeDialogApiErrorHandler = () => {
    setIsProviderFailed(false);
    window.location.href = '/'
  };

  const sendFailedFiles = (_form) => {
    saveAttachedFilesHandler(_form.values);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Nuevo Registro de Proveedor
            </Typography>
            <Formik
              validate={formikValidate}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={saveAdhesion}
              >
              {formik => (
                <form
                encType="multipart/form-data"
                onSubmit={formik.handleSubmit}
                noValidate
                >
                  <Grid container style={{'marginTop': 15}}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Tipo de proveedor: </FormLabel>
                        <RadioGroup
                          aria-label="proveedor"
                          name="proveedor"
                          value={tipoProveedor}
                          onChange={(ev) => {
                            handleProveedorChange(ev, formik)
                          }}
                          style={{'flexDirection': 'row'}}
                        >
                          <FormControlLabel value="F" control={<Radio />} label="Persona Física" />
                          <FormControlLabel value="J" control={<Radio />} label="Jurídica" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>                  
                  <Grid container spacing={3} style={{'marginTop': 35}}>
                    <Grid item xs={12} sm={3}>
                      <FormLabel component="legend">Cédula: </FormLabel>
                      <Field
                        mask={mask}
                        name="cedula"
                        component={PlnInput}
                        />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      className={classes.searchContainer}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={searchBtnEnabled}
                        className={classes.btnSearch}
                        onClick={async () => {
                          await getDataByCed(formik);
                        }}
                      >
                        {searchBtnLabel}
                      </Button>
                    </Grid>                    
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      {!isProveedorValid || (formik.errors.cedula &&
                          formik.touched.cedula) ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {validProveedorMsj}
                              </Alert>
                            </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 10 }} />
                  {showForm ? (
                    <>
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 20 }}
                      >

                        <Grid item xs={12} sm={4}>
                          <h4>Número de identificación*:</h4>
                          <Field
                            name="id"
                            mask={mask}
                            data={defaultData.id}
                            disabled
                            component={PlnInput}
                          />
                          {formik.errors.id &&
                          formik.touched.id ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.id}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Nombre*:</h4>
                          <Field
                            name="nombre"
                            data={defaultData.nombre}
                            component={PlnInput}
                          />
                          {formik.errors.nombre &&
                          formik.touched.nombre ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.nombre}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>                        
                      </Grid>
                      {tipoProveedor !== "F" ? (
                      <Grid
                      container
                      spacing={3}
                      style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={
                              classes.sectionTitle
                            }
                          >
                            Representante Legal
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Identificación:</h4>
                          <Field
                            name="legalId"
                            data={defaultData.legalId}
                            component={PlnInput}
                          />
                          {formik.errors.legalId &&
                          formik.touched.legalId ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.legalId}
                              </Alert>
                            </div>
                          ) : null}                          
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Nombre:</h4>
                          <Field
                            name="legalNombre"
                            data={defaultData.legalNombre}
                            component={PlnInput}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Teléfono:</h4>
                          <Field
                            mask="99999999"
                            name="legalTelefono"
                            data={defaultData.legalTelefono}
                            component={PlnInput}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Correo Electrónico:</h4>
                          <Field
                            name="legalEmail"
                            data={defaultData.legalEmail}
                            component={PlnInput}
                          />
                        </Grid>                        
                      </Grid>                        
                      ) : ('')}
                      <Grid
                      container
                      spacing={3}
                      style={{ marginTop: 20 }}
                      >                        
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={
                              classes.sectionTitle
                            }
                          >
                            Dirección Exacta
                          </Typography>                          
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <h4>Provincia</h4>
                          <Field
                            name="provincia"
                            data={provincias}
                            component={PlnDropdown}
                            onChange={provincia => {
                              setProvincia(provincia);
                              formik.setFieldValue('canton', 999);
                              formik.setFieldValue('distrito', 999);                              
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <h4>Cantón</h4>
                          <Field
                            name="canton"
                            data={cantones}
                            component={PlnDropdown}
                            onChange={canton => {
                              setCanton(canton);
                              formik.setFieldValue('distrito', 999);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <h4>Distrito Administrativo</h4>
                          <Field
                            name="distrito"
                            data={distritos}
                            component={PlnDropdown}
                            onChange={distrito =>
                              setDistrito(distrito)
                            }
                          />
                        </Grid>                        
                        <Grid item xs={12} sm={3}>
                          <h4>Dirección:</h4>
                          <Field
                            name="direccion"
                            data={defaultData.direccion}
                            component={PlnInput}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                      container
                      spacing={3}
                      style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={
                              classes.sectionTitle
                            }
                          >
                            Otras Señas
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Teléfono*:</h4>
                          <Field
                            mask="99999999"
                            name="telefono"
                            data={defaultData.telefono}
                            component={PlnInput}
                          />
                          {formik.errors.telefono &&
                          formik.touched.telefono ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.telefono}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Correo Electrónico*:</h4>
                          <Field
                            type="email"
                            name="email"
                            data={defaultData.email}
                            component={PlnInput}
                          />
                          {formik.errors.email &&
                          formik.touched.email ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.email}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Página Web:</h4>
                          <Field
                            name="web"
                            data={defaultData.web}
                            component={PlnInput}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                      container
                      spacing={3}
                      style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={
                              classes.sectionTitle
                            }
                          >
                            Contacto
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Identificación:</h4>
                          <Field
                            mask={mask}
                            name="contactId"
                            data={defaultData.contactId}
                            component={PlnInput}
                          />
                          {formik.errors.contactId &&
                          formik.touched.contactId ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.contactId}
                              </Alert>
                            </div>
                          ) : null}                          
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Nombre:</h4>
                          <Field
                            name="contactNombre"
                            data={defaultData.contactNombre}
                            component={PlnInput}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Teléfono:</h4>
                          <Field
                            mask="99999999"
                            name="contactTelefono"
                            data={defaultData.contactTelefono}
                            component={PlnInput}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <h4>Correo Electrónico:</h4>
                          <Field
                            name="contactEmail"
                            data={defaultData.contactEmail}
                            component={PlnInput}
                          />
                        </Grid>                        
                      </Grid>
                      <Grid
                      container
                      spacing={3}
                      style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={
                              classes.sectionTitle
                            }
                          >
                            Cuenta Bancaria
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Cuenta IBAN número*:</h4>
                          <Field
                            maxlength={22}
                            name="IBAN"
                            data={defaultData.IBAN}
                            component={PlnInput}
                          />
                          {formik.errors.IBAN &&
                          formik.touched.IBAN ? (
                            <div
                              className={classes.formError}
                            >
                              <Alert severity="error">
                                {formik.errors.IBAN}
                              </Alert>
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={8} className={classes.centerElements}>
                          <p>Nuestros pagos son en colones mediante transferencia con el Banco de Costa Rica</p>
                        </Grid>

                      </Grid>
                      <Grid
                      container
                      spacing={3}
                      style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={
                              classes.sectionTitle
                            }
                          >
                            &nbsp;
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h4>Bienes y Servicios ofrecidos:</h4>
                        </Grid>
                        <Grid item xs={12} sm={8} className={classes.centerElements}>
                          <Field
                            multiLine
                            name="services"
                            data={defaultData.services}
                            component={PlnInput}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                      container
                      spacing={3}
                      style={{ marginTop: 20 }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            variant="h6"
                            color="primary"
                            gutterBottom
                            className={
                              classes.sectionTitle
                            }
                          >
                            Requisitos que deben adjuntarse, además de formulario debidamente lleno
                          </Typography>
                        </Grid>
                        {tipoProveedor !== "F" ? (
                          <React.Fragment>
                            <Grid item xs={12} sm={6}>
                              <h4 className={classes.formFiletitle}>a) Personería Jurídica actualizada con no más de un mes de emitida</h4>
                              {formik.errors.filePersoneria && formik.touched.filePersoneria ? (
                                <div
                                  className={classes.formError}
                                >
                                  <Alert severity="error">
                                    {formik.errors.filePersoneria}
                                  </Alert>
                                </div>
                              ) : null}                              
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.centerElementsRow}>
                              <input
                                className={classes.hideElement}
                                id="contained-file-personeria"
                                name="filePersoneria"
                                type="file"
                                onChange={(ev) => {
                                  formik.setFieldValue("filePersoneria", ev.currentTarget.files[0]);
                                  setPersoneria(ev.currentTarget.files[0]);
                                }}                          
                                />
                              <label htmlFor="contained-file-personeria">
                                <Button variant="contained" color="primary" component="span">Seleccione Personería</Button>
                              </label>
                              <p className={classes.formFiletitle}>{personeriaFile ? personeriaFile.name : ''}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4 className={classes.formFiletitle}>b) Copia de la cédula de identidad del representante legal</h4>
                              {formik.errors.fileCedRepresentanteLegal && formik.touched.fileCedRepresentanteLegal ? (
                                <div
                                  className={classes.formError}
                                >
                                  <Alert severity="error">
                                    {formik.errors.fileCedRepresentanteLegal}
                                  </Alert>
                                </div>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.centerElementsRow}>
                              <input
                                className={classes.hideElement}
                                id="contained-file-legal"
                                name="fileCedRepresentanteLegal"
                                type="file"
                                onChange={(ev) => {
                                  formik.setFieldValue("fileCedRepresentanteLegal", ev.currentTarget.files[0]);
                                  setReprLegal(ev.currentTarget.files[0]);
                                }}                          
                                />
                              <label htmlFor="contained-file-legal">
                                <Button variant="contained" color="primary" component="span">Seleccione Cédula</Button>
                              </label>
                              <p className={classes.formFiletitle}>{repLegalFile ? repLegalFile.name : ''}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <h4 className={classes.formFiletitle}>c) Documento bancario donde conste la cuenta IBAN</h4>
                              {formik.errors.fileIBANJur && formik.touched.fileIBANJur ? (
                                <div
                                  className={classes.formError}
                                >
                                  <Alert severity="error">
                                    {formik.errors.fileIBANJur}
                                  </Alert>
                                </div>
                              ) : null}                              
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.centerElementsRow}>
                              <input
                                className={classes.hideElement}
                                id="contained-file-IBANJuridico"
                                name="fileIBANJur"
                                type="file"
                                onChange={(ev) => {
                                  formik.setFieldValue("fileIBANJur", ev.currentTarget.files[0]);
                                  setIbanJuri(ev.currentTarget.files[0]);
                                }}                          
                                />
                              <label htmlFor="contained-file-IBANJuridico">
                                <Button variant="contained" color="primary" component="span">Seleccione Cuenta IBAN</Button>
                              </label>
                              <p className={classes.formFiletitle}>{ibanFileJuri ? ibanFileJuri.name : ''}</p>
                            </Grid>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Grid item xs={12} sm={6}>
                              <h4 className={classes.formFiletitle}>a) Copia de la cédula de identidad</h4>
                              {formik.errors.fileCedula && formik.touched.fileCedula ? (
                                <div
                                  className={classes.formError}
                                >
                                  <Alert severity="error">
                                    {formik.errors.fileCedula}
                                  </Alert>
                                </div>
                              ) : null}                                
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.centerElementsRow}>
                              <input
                                className={classes.hideElement}
                                id="contained-file-cedula"
                                name="fileCedula"
                                type="file"
                                onChange={(ev) => {
                                  formik.setFieldValue("fileCedula", ev.currentTarget.files[0]);
                                  setCedulaFile(ev.currentTarget.files[0]);
                                }}                          
                                />
                              <label htmlFor="contained-file-cedula">
                                <Button variant="contained" color="primary" component="span">Seleccione Cédula</Button>
                              </label>
                              <p className={classes.formFiletitle}>{cedulaFile ? cedulaFile.name : ''}</p>
                            </Grid>                            
                            <Grid item xs={12} sm={6}>
                              <h4 className={classes.formFiletitle}>b) Documento bancario donde conste la cuenta IBAN</h4>
                              {formik.errors.fileIBAN && formik.touched.fileIBAN ? (
                                <div
                                  className={classes.formError}
                                >
                                  <Alert severity="error">
                                    {formik.errors.fileIBAN}
                                  </Alert>
                                </div>
                              ) : null}
                            </Grid>                            
                            <Grid item xs={12} sm={6} className={classes.centerElementsRow}>
                              <input
                                className={classes.hideElement}
                                id="contained-file-IBAN-fisico"
                                name="fileIBAN"
                                type="file"
                                onChange={(ev) => {
                                  formik.setFieldValue("fileIBAN", ev.currentTarget.files[0]);
                                  setIban(ev.currentTarget.files[0]);
                                }}                          
                                />
                              <label htmlFor="contained-file-IBAN-fisico">
                                <Button variant="contained" color="primary" component="span">Seleccione Cuenta IBAN</Button>
                              </label>
                              <p className={classes.formFiletitle}>{ibanFile ? ibanFile.name : ''}</p>
                            </Grid>                            
                          </React.Fragment>
                        )}
                      </Grid>                      
                      <Grid
                        container
                        spacing={3}
                        style={{ marginTop: 40 }}
                      >
                        <Grid item xs={12}>
                          {isFilesFailed ? (
                            <Button
                              type="button"
                              variant="contained"
                              color="secondary"
                              size="small"
                              className={
                                isSaving
                                  ? classes.btnSubmitting
                                  : classes.btnActions
                              }
                              disabled={isSaving}
                              onClick={(ev) => {
                                ev.preventDefault();
                                sendFailedFiles(formik);
                              }}
                            >
                            {isSaving ? (
                              <CircularProgress
                                style={{
                                  width: '30px',
                                  height: '30px',
                                }}
                              />
                            ) : (
                              <>
                                Re-enviar Archivos
                                <SaveIcon
                                  className={
                                    classes.frmIconButton
                                  }
                                />
                              </>
                            )}
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                              size="small"
                              className={
                                formik.isSubmitting ||
                                isSaving
                                  ? classes.btnSubmitting
                                  : classes.btnActions
                              }
                              disabled={isSaving}
                            >
                              {isSaving ? (
                                <CircularProgress
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                  }}
                                />
                              ) : (
                                <>
                                  Enviar Solicitud
                                  <SaveIcon
                                    className={
                                      classes.frmIconButton
                                    }
                                  />
                                </>
                              )}
                            </Button>                            
                          )}
                        </Grid>
                      </Grid>                                             
                    </>
                  ) : ('')}
                </form>                
              )}
            </Formik>
            <PLNDialog
              open={open}
              onClose={() => {}}
              title="Mensaje"
              bodymessage={modalBodyMsj}
              buttons={[
                {
                  actionName: 'close',
                  color: 'simple',
                  title: 'Entendido',
                  clickHander: closeDialogHandler,
                },
              ]}
            />
            <PLNDialog
              open={isProviderFailed}
              onClose={() => {}}
              title="Mensaje"
              bodymessage={'Se ha presentado un error al guardar la información. Intente de nuevo!.'}
              buttons={[
                {
                  actionName: 'close',
                  color: 'simple',
                  title: 'Entendido',
                  clickHander: closeDialogApiErrorHandler,
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddProveedor;