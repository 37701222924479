import axios from 'axios';
import { useQuery } from 'react-query';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const getProvincesItems = async data => {
  const frmOptions = data.map(p => {
    return {
      itemLabel: p.des_nombre_p,
      value: p.cod_provincia,
    };
  });

  frmOptions.unshift({
    itemLabel: '- SELECCIONE LA PROVINCIA -',
    value: 9,
  });

  const initValues = {
    provincia: 9,
    canton: 999,
    distrito: 999,
    papeleta: 999,
  };

  return {
    provinciasData: data,
    frmOptions,
    initValues,
  };
};

const getMovimientosItems = async data => {
  const frmOptions = data.map(p => {
    return {
      itemLabel: p.DES,
      value: p.COD,
    };
  });

  frmOptions.unshift({
    itemLabel: '- SELECCIONE EL MOVIMIENTO -',
    value: 9,
  });

  const initValues = {
    movimiento: 9,
  };

  return {
    movimientosData: data,
    frmOptions,
    initValues,
  };
};
const getSectoresItems = async data => {
  const frmOptions = data.map(p => {
    return {
      itemLabel: p.DES,
      value: p.COD,
    };
  });

  frmOptions.unshift({
    itemLabel: '- SELECCIONE EL SECTOR -',
    value: 9,
  });

  const initValues = {
    sector: 9,
  };

  return {
    sectoresData: data,
    frmOptions,
    initValues,
  };
};

export const getMovimientosCantonales = async () => {
  try {
    const url = [`/MovimientosCantonales`];

    const { data } = await axios.get(url.join('&'));

    const movimientosData = getMovimientosItems(
      data.response,
    );

    return movimientosData;
  } catch (e) {
    return { movimientosData: {} };
  }
};

export const getSectores = async () => {
  try {
    const url = [`/sectores`];

    const { data } = await axios.get(url.join('&'));

    const sectoresData = getSectoresItems(data.response);

    return sectoresData;
  } catch (e) {
    return { sectoresData: {} };
  }
};

export const getProvinces = async () => {
  try {
    const url = [`/provinces`];

    const { data } = await axios.get(url.join('&'));
    const provinciasData = getProvincesItems(data.response);

    return provinciasData;
  } catch (e) {
    return { provinciasData: {} };
  }
};

export const getCantonsByProvince = idProvince => {
  const url = [`/cantons/findByProvince/${idProvince}`];

  return axios.get(url.join('&'));
};

export const getDistritoBy = (idProvince, idCanton) => {
  const url = [`/districts/${idProvince}/${idCanton}`];

  return axios.get(url.join('&'));
};

export const getDistritoByTwo = (idProvince, idCanton) => {
  const url = [
    `/districts/getBy2/${idProvince}/${idCanton}`,
  ];

  return axios.get(url.join('&'));
};

export const getCantidadDelegados = (
  idProvince,
  idCanton,
  idDistrito,
) => {
  const url = [
    `/districts/delegados/${idProvince}/${idCanton}/${idDistrito}`,
  ];

  return axios.get(url.join('&'));
};

export const useProvincias = () => {
  return useQuery(['provinciasData'], () => getProvinces());
};

export const useMovimientos = () => {
  return useQuery(['movimientosData'], () =>
    getMovimientosCantonales(),
  );
};

export const useSectores = () => {
  return useQuery(['sectoresData'], () => getSectores());
};
