import {
  colors,
  breakpoints as bp,
} from '../../Core/Theme/theme';

const styles = theme => ({
  container: {
    background: colors.backGray,
  },
  header: {
    color: '#444',
    fontFamily: 'Arial',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logoContainer: {
    height: 72
  },
  logoParent: {
    display: 'flex',
    alignItems: 'center'
  },
  imgLogo: {
    //maxWidth: '100%',
    height: '100%',
    display: 'block',
    padding: 10,
    [theme.breakpoints.down(bp.MOBILE)]: {
      paddingTop: 25,
    },
  },
});

export default styles;
